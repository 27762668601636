import React from "react";
import { Modal, Button, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";
import Installment from "../../../PaymentPlan/components/Installment";


const DetailsPlanModal = ({ visible, onCloseModal, detailsPlan, name }) => {
    console.log(detailsPlan)
    return (
        <Modal backdrop="blur" isOpen={visible} onClose={onCloseModal} size="1xl">
            <ModalContent className="text-white bg-zinc-800">
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1 text-center">
                            <TitleModal>Plan de Pagos</TitleModal>
                        </ModalHeader>
                        <ModalBody>
                            <div className="flex flex-col ">
                                <p className="text-lg font-bold">{name}</p>
                                <div className="flex">
                                    <p className="text-lg font-bold">Total: </p>
                                    <p className="text-lg text-bold mx-1">${detailsPlan?.totalCost}</p>
                                </div>
                            </div>
                            <Installment
                                paymentPlan={detailsPlan?.installment}
                                isDisabled
                            />
                            <Button onClick={onCloseModal}>Cerrar</Button>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default DetailsPlanModal;
