import React from "react";
import { Select, SelectItem, Avatar, Button, Chip } from "@nextui-org/react";
import BookIcon from "@assets/icons/book.png"
// import { users } from "./data";

function SeletEnrolmentsList({ enrolments, fetchEnrolment, loading, loadingEnrolments }) {
    const [enrolmentSelected, setEnrolmentSelected] = React.useState();
    const { activeSubjects, finishSubjects } = enrolments;

    const getItems = (actives, finished) => {
        if (actives?.length > 0) return actives.concat(finished || [])
        else return finished || []
    }

    const onSelectEnrolment = (enrolment) => {
        if (!!enrolment?.currentKey) {
            setEnrolmentSelected(enrolment.currentKey);
        }
    };

    const onFecthEnrolment = () => {
        if (!!enrolmentSelected) fetchEnrolment(enrolmentSelected);
    }

    // if (!enrolments) return

    return (
        <div className="flex flex-col w-full items-center h-full pt-32">
            <div className="text-3xl font-bold pb-5">Materias</div>
            <Select
                items={getItems(activeSubjects, finishSubjects)}
                onSelectionChange={onSelectEnrolment}
                label={loadingEnrolments ? "Buscando materias activas..." : "Seleccionar materia"}
                className="max-w-lg text-black border-zinc-200"
                variant="faded"
                classNames={{
                    label: "group-data-[filled=true]:-translate-y-5",
                    trigger: "min-h-unit-16",
                    listboxWrapper: "max-h-[400px]",
                }}
                listboxProps={{
                    itemClasses: {
                        base: [
                            "rounded-md",
                            "text-default-500",
                            "transition-opacity",
                            "data-[hover=true]:text-foreground",
                            "data-[hover=true]:bg-white",
                            "dark:data-[hover=true]:bg-default-50",
                            "data-[selectable=true]:focus:bg-default-50",
                            "data-[pressed=true]:opacity-70",
                            "data-[focus-visible=true]:ring-default-500",
                        ],
                    },
                }}
                popoverProps={{
                    classNames: {
                        base: "before:bg-default-200",
                        content: "p-0 border-small border-divider bg-background",
                    },
                }}
                isLoading={loadingEnrolments}
                isDisabled={loadingEnrolments}
                renderValue={(items) => {
                    return items.map((item) => (
                        <div key={item.key} className="flex items-center gap-2">
                            <Avatar
                                alt={item.data.name}
                                className="flex-shrink-0"
                                size="sm"
                                src={BookIcon}
                            />
                            <div className="flex flex-col">
                                <span>{item.data.name}</span>
                                <span className="text-default-500 text-tiny">({item.data.professor})</span>
                            </div>
                        </div>
                    ));
                }}
            >
                {(user) => (
                    <SelectItem key={user.idEnrolment} textValue={user.name}>
                        <div className="flex gap-2 items-center">
                            <Avatar alt="book" className="flex-shrink-0" size="sm" src={BookIcon} />
                            <div className="flex flex-col">
                                <div>
                                    <span className="text-small">{user.name}</span>
                                    {user.dateFinish > new Date().toISOString()
                                        &&
                                        <Chip
                                            color="success"
                                            size="sm"
                                            variant="flat"
                                            style={{ width: "100%" }}
                                        >
                                            Activa
                                        </Chip>
                                    }
                                    {user.dateFinish < new Date().toISOString()
                                        &&
                                        <Chip
                                            color="danger"
                                            size="sm"
                                            variant="flat"
                                            style={{ width: "100%" }}
                                        >
                                            Finalizada
                                        </Chip>
                                    }
                                </div>
                                <span className="text-tiny text-default-400">{user.professor}</span>
                            </div>
                        </div>
                    </SelectItem>
                )}
            </Select>

            <div className="flex w-full pt-20 justify-center items-center">
                <Button
                    color="primary"
                    variant="solid"
                    size="lg"
                    isDisabled={!enrolmentSelected}
                    onClick={onFecthEnrolment}
                    isLoading={loading}
                >
                    Aceptar
                </Button>
            </div>
        </div >
    );
}

export default SeletEnrolmentsList;
