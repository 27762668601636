import axios from "../axios";
import format from "../format";
import { getDetailPaymentPlanURL } from "./URLs";

const getDetailPaymentPlan = async ({ paymentId }) => {
    try {
        const BASE_URL = `${process.env.REACT_APP_API_URL || ""}`;
        const URL = format(BASE_URL + getDetailPaymentPlanURL, paymentId);
        const response = await axios().get(URL);
        return response || {};
    } catch {
        return {};
    }
};

export default getDetailPaymentPlan;
