import { Progress } from "@nextui-org/react";
import React, { useState } from "react";
import DetailsPlanModal from "../DetailsPlanModal";
import getDetailPaymentPlan from "@services/payment/getDetailPaymentPlan";
import { useMutation } from "react-query";
import isEmptyObject from "@helpers/isEmptyObject";
import { enqueueSnackbar } from "notistack";

const TableUsersEnrolments = ({ users }) => {
  const [viewDetailsPlan, setViewDetailsPlan] = useState();
  const [detailsPlan, setDetailsPlan] = useState({});
  const [name, setName] = useState();

  const closeDetailsModal = () => {
    setViewDetailsPlan(false);
    setName("")
  };

  const onGetPaymentPlan = ({ name, paymentId }) => {
    setName(name)
    setViewDetailsPlan(false);
    fetchPaymentPlan({ paymentId: paymentId })
  };


  const { mutate: fetchPaymentPlan, isLoading: isLoadingDetailsPlan } = useMutation(
    getDetailPaymentPlan,
    {
      onSuccess: (data) => {
        if (!isEmptyObject(data) && data.status === 200) {
          setDetailsPlan(data.data.data[0]);
          setViewDetailsPlan(true);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          persist: false,
        });
      },
    },
  );


  const getStatusClass = (packageItem) => {
    if (packageItem?.paymentInfo?.status === "done") {
      return "bg-success text-success";
    } else if (packageItem?.paymentInfo?.status === "danger") {
      return "bg-danger text-danger";
    } else if (packageItem?.paymentInfo?.status === "warning") {
      return "bg-warning text-warning";
    } else {
      return "bg-white text-white";
    }
  };
  return (
    <div className="rounded-sm px-5 pb-2.5 pt-6 shadow-default border-strokedark bg-teal-800 sm:px-7.5 min-h-screen">
      <DetailsPlanModal
        visible={viewDetailsPlan}
        onCloseModal={closeDetailsModal}
        detailsPlan={detailsPlan}
        name={name}
      />
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 bg-foreground-200 text-left">
              <th className="min-w-[220px] px-4 py-4 font-medium text-black xl:pl-11">
                Alumno
              </th>
              <th className="min-w-[150px] px-4 py-4 font-medium text-black ">
                Progreso de clases
              </th>
              <th className="min-w-[120px] px-4 py-4 font-medium text-black ">
                Pago
              </th>
              <th className="px-4 py-4 font-medium text-black">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.length === 0 &&
              <div className="text-lg w-full pt-5 pl-3">No hay resultados</div>
            }
            {users?.map((packageItem, key) => (
              <tr key={key}>
                <td className="border-b border-[#eee] px-4 py-5 pl-9 dark:border-strokedark xl:pl-11">
                  <h5 className="font-medium  text-white">
                    {packageItem?.name}
                  </h5>
                  <p className="text-sm text-teal-100">{packageItem?.phone}</p>
                </td>
                <td className="border-b border-[#eee] px-4 py-5 dark:border-strokedark">
                  <Progress
                    aria-label="Downloading..."
                    size="md"
                    value={packageItem?.videoProgress}
                    color="success"
                    showValueLabel={true}
                    className="max-w-md"
                  />
                </td>
                <td className="border-b border-[#eee] px-4 py-5 dark:border-strokedark">
                  <p
                    className={`inline-flex rounded-full bg-opacity-10 px-3 py-1 text-sm font-medium ${getStatusClass(packageItem)}`}
                  >
                    {packageItem?.paymentInfo?.message}
                  </p>
                  {packageItem?.paymentInfo?.quotesExpirates >= 1 && <p className="mx-3 text-yellow-500">Cuotas impagas: {packageItem?.paymentInfo?.quotesExpirates}</p>}
                </td>
                <td className="border-b border-[#eee] px-4 py-5 dark:border-strokedark">
                  <div className="flex items-center space-x-3.5">
                    <button className="hover:text-primary flex items-center" onClick={() => onGetPaymentPlan({
                      name: packageItem?.name,
                      paymentId: packageItem?.paymentInfo?.paymentId
                    })}
                    >
                      <svg
                        className="fill-current"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                          fill=""
                        />
                        <path
                          d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                          fill=""
                        />
                      </svg>
                      <p className="mx-2">Ver Pagos</p>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div >
  );
};

export default TableUsersEnrolments;
